<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <SearchBar></SearchBar>
    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <div
      style="background-image: url(banner2.jpg); background-size: cover; background-position: bottom;"
      class="border-b  pb-4 pt-8 mb-8 w-full"
    >
      <div class="flex flex-col space-y-2 py-20">
        <div class="flex mx-auto space-x-2 ">
          <h2 class="font-bold text-4xl uppercase text-black px-4">
            Speed Boat Charter Service
          </h2>
        </div>
      </div>
    </div>

    <!--Container - Popular Resorts -->
    <div class=" w-full mx-auto pt-0 bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex flex-wrap items-center justify-center">
          <div class="mb-20"><img src="" /></div>

          <div class="w-full grid grid-cols-1 gap-4 md:grid-cols-3 ">
            <div class="bg-white  text-sm text-left ">
              <h4 class="text-xl text-black font-semibold ">
                Speed Boat Transfer
              </h4>

              <ul class="py-2 mb-10">
                <li class="py-1">- K. Thulusdhoo (35 Mins Duration)</li>
                <li class="py-1">- K. Maafushi (35 Mins Duration)</li>
                <li class="py-1">- K. Dhiffushi (45 Mins Duration)</li>
                <li class="py-1">- K. Himmafushi (25 Mins Duration)</li>
                <li class="py-1">- K. Huraa (25 Mins Duration)</li>
                <li class="py-1">- K. Guraidhoo (40 Mins Duration)</li>
              </ul>
            </div>
            <div class="bg-white  text-sm text-left ">
              <h4 class="text-xl text-black font-semibold">
                Sand Bank Trips
              </h4>

              <ul class="py-2 mb-10">
                <li class="py-1">- Sand Bank Corel Tree</li>
                <li class="py-1">- Maniyafushi Sand Bank</li>
                <li class="py-1">- Kohdhipparu Sand Bank</li>
                <li class="py-1">- White Sand Bank</li>
                <li class="py-1">- Azlia Sand Bank</li>
                <li class="py-1">- Waldof Astoria Ithafushi Sand Bank</li>
                <li class="py-1">- Tolhimaa Huraa Sand Bank</li>
              </ul>
            </div>
            <div class="bg-white  text-sm text-left ">
              Please contact the below number <br />or email for inquiries:

              <div class="mt-3 mb-10">
                <div class="flex items-center text-lg mb-2">
                  <svg
                    class="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                    ></path>
                  </svg>
                  +960 971-1757
                </div>

                <div class="flex items-center text-lg">
                  <svg
                    class="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                    ></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>

                  sales@nalafalhuholidays.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";
import SearchBar from "@/components/others/SearchBar";

export default {
  name: "OffersPage",
  data() {
    return {
      properties: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout,
    SearchBar
  }
};
</script>

<style scoped></style>
